import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Rx";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: "root"
})
export class TeamService {
    constructor(private api: ApiService) {}

    findCares(): Observable<any> {
        return this.api.get("/pros/account/cares");
    }

    updateTour(tour, area): Observable<any> {
        return this.api.put("/pros/account/team/tour", {
            tour: tour,
            area: area
        });
    }

    updateOffice(office): Observable<any> {
        return this.api.put("/pros/account/team/office", office);
    }

    joinOtherTeam(team_id, pro_id): Observable<any> {
        return this.api.post("/pros/account/team/join/" + team_id, {
            id: pro_id
        });
    }

    getTeamByUrl(office): Observable<any> {
        return this.api.get(
            "/pros/account/team/jobId/" +
                office.job_id +
                "/city/" +
                office.office_city +
                "/zipcode/" +
                office.office_zipcode +
                "/name/" +
                office.name
        );
    }

    getTour(): Observable<any> {
        return this.api.get("/pros/account/team/office/tour");
    }

    updateCare(care): Observable<any> {
        return this.api.put("/pros/account/cares/" + care.id, care);
    }

    findSpecialties(): Observable<any> {
        return this.api.get("/pros/account/specialties");
    }

    updateSpecialty(specialty): Observable<any> {
        return this.api.put(
            "/pros/account/specialties/" + specialty.id,
            specialty
        );
    }

    updatePaymentMethods(team): Observable<any> {
        return this.api.put("/pros/account/team/payment", team);
    }

    getColleagues(): Observable<any> {
        return this.api.get("/pros/account/team/colleagues");
    }

    getTeam() {
        return JSON.parse(localStorage.getItem("team"));
    }

    getSchedule() {
        return this.api.get("/pros/account/schedule");
    }

    updateSchedule(data) {
        return this.api.put("/pros/account/schedule", data);
    }

    signCharter(pro) {
        return this.api.post("/pros/account/signCharter", pro);
    }
}
