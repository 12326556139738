import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CheckIsAuthenticated} from './guard';
import {AuthenticationGuard} from './guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './pages/auth/login/login.module#LoginPageModule',
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'register',
        loadChildren: './pages/auth/register/register.module#RegisterModule'
    },
    {
        path: 'tabs',
        loadChildren: './pages/tabs/tabs.module#TabsPageModule',
        canLoad: [CheckIsAuthenticated]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
