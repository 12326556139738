import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Rx";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: "root"
})
export class ProductService {
      private jsonDataUrl = 'assets/data.json';
 constructor(private api: ApiService) { }

  getData(): Observable<any> {
    return this.api.get("/admin/sheet-product");
  }
  getProductSheetById(id){
     return this.api.get(`/admin/sheet-product/${id}`)
  }

}