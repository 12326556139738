import {Injectable} from "@angular/core";
import "rxjs/add/operator/map";
import {Observable} from "rxjs/Observable";

@Injectable({
    providedIn: "root"
})
export class AssignationsService {
    constructor(private api: ApiService) {}

    getActiveAndForthcomingAssignations(): Observable<any> {
        return this.api.get(
            "/pros/account/assignations/active_and_forthcoming/mobile"
        );
    }

    getArchivedAssignations(): Observable<any> {
        return this.api.get("/pros/account/assignations/archived/mobile");
    }

    getAssignationById(id): Observable<any> {
        return this.api.get("/pros/account/assignations/" + id);
    }

    getCoordinatorPatientMcvInfos(patientId, slotId): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
                patientId +
                "/slot/" +
                slotId +
                "/coordinator-patient-mcv-infos"
        );
    }

    accept(id: number) {
        return this.api.put(
            "/pros/account/assignations/" + id + "/accepted",
            null
        );
    }

    cancel(id: number, {cancelReason, awayDates}) {
        return this.api.put("/pros/account/assignations/" + id + "/cancelled", {
            cancelReason,
            awayDates
        });
    }

    getNbOpenAndForthcomingAssignations() {
        return this.api.get(
            "/pros/account/assignations/open_and_forthcoming/nb"
        );
    }

    updateAstraResult(astraResult: boolean, assignationId: number) {
        return this.api.put(
            "/pros/account/assignations/" + assignationId + "/astra-result",
            {astraResult}
        );
    }
}

import {ApiService} from "./api.service";
