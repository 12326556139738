import {NgModule, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {AgmCoreModule} from '@agm/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import localeFr from '@angular/common/locales/fr';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Device} from '@ionic-native/device/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {PipesModule} from './pipes/pipes.module';
import {HttpConfigInterceptor} from './services/httpConfig.interceptor';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {Network} from '@ionic-native/network/ngx';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {PdfViewerModule} from 'ng2-pdf-viewer';

registerLocaleData(localeFr);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            backButtonText: 'Retour'
        }),
        AppRoutingModule,
        HttpClientModule,
        PipesModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAXlh8ocwxvij2WCEIUtE-5-oKsnJBR540',
            libraries: ['places'],
            region: 'FR',
            language: 'fr-FR'
        })
    ],
    providers: [
        Network,
        Device,
        InAppBrowser,
        StatusBar,
        Clipboard,
        PdfViewerModule,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
