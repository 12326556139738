import {Injectable} from '@angular/core';
import {CanLoad, Router} from '@angular/router';
import {AuthService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class CheckIsAuthenticated implements CanLoad {
    constructor(
        private authenticationService: AuthService,
        private router: Router
    ) {
    }

    canLoad() {
        if (AuthService.isAuthenticated()) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
