import {Injectable} from "@angular/core";
import "rxjs/add/operator/map";
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Rx";

@Injectable({
    providedIn: "root"
})
export class GeocoderService {
    constructor(private api: ApiService) {}

    getAddress(address: string): Observable<any> {
        return this.api.get("/pros/account/address/infos/" + address);
    }
}
