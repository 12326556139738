import {Injectable} from "@angular/core";
import "rxjs/add/operator/map";
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Rx";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    constructor(private api: ApiService) {}

    info() {
        return this.api.get("/pros/account/info");
    }

    pro() {
        return this.api.get("/pros/account/info");
    }

    updatePro(pro) {
        return this.api.put("/pros/account/", pro);
    }

    updateProEmail(pro) {
        return this.api.put("/pros/account/email", pro);
    }

    updateTeam(team) {
        return this.api.put("/pros/account/team/office", team);
    }

    updateVisibilityAndSendConfirmationEmail(data): Observable<any> {
        return this.api.put(`/pros/account/team/visibility`, data);
    }

    createComingBackCronJob(leaveDate) {
        return this.api.post("/pros/account/leave", {leaveDate: leaveDate});
    }

    uploadImage(image) {
        return this.api.post("/pros/account/mobile/avatar", image);
    }

    refreshPro(): Observable<any> {
        return this.api.get("/pros/account/refresh");
    }

    updateProLanguages(languages) {
        return this.api.post("/pros/account/languages", languages);
    }

    loadLanguages(): Observable<any> {
        return this.api.get("/pros/account/languages");
    }

    getPatient(): Observable<any> {
        return this.api.get("/pros/account/patients");
    }

    saveSanofiRequest(data): Observable<any> {
        return this.api.post("/pros/account/sanofi", data);
    }

    sendInvitation(phone) {
        return this.api.post("/meetings/create", {
            phone: phone
        });
    }

    sendInvitationForMission(patientId: number, missionId: number) {
        return this.api.post(
            `/meetings/mission/${missionId}/patient/${patientId}`,
            {}
        );
    }

    tellStaffForJoinMeeting(data) {
        return this.api.post("/meetings/tell-staff", data);
    }

    updateShareCount(socialMedium) {
        return this.api.post("/pros/account/sharing", {socialMedium});
    }

    getReferralData(): Observable<any> {
        return this.api.get("/pros/account/referral");
    }

    savePathwayRequest(form): Observable<any> {
        return this.api.post("/pros/account/pathway", {form});
    }

    setInfosInvoice(infosInvoice: any = {}): Observable<any> {
        return this.api.post(
            "/pros/account/missions/infos-invoice",
            infosInvoice
        );
    }

    getInfosInvoice(): Observable<any> {
        return this.api.get("/pros/account/missions/infos-invoice");
    }

    updateReferralGiftPreference(newReferralGift): Observable<any> {
        return this.api.put("/pros/account/referral/gift", {newReferralGift});
    }
}
