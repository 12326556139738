import {NgModule} from "@angular/core";
import {SafePipe} from "./safe.pipe";
import {AssignationStatusPipe} from "./assignationStatus.pipe";
import {AssignationDetailsStatusPipe} from "./assignationDetailsStatus.pipe";
import {MomentTimePipe} from "./momentTime.pipe";
import {ChangeStyleDependingOnTimeElapsedPipe} from "./changeStyleDependingOnTimeElapsed.pipe";
import {HtmlPipe} from "./html.pipe";
import {MissionChipStatusPipe} from "./missionChipStatus.pipe";
import {MissionTypePipe} from "./missionType.pipe";
import {IdentificationMission} from "./identificationMission.pipe";
import {PainScalePipe} from "./painScale.pipe";
@NgModule({
    declarations: [
        AssignationStatusPipe,
        AssignationDetailsStatusPipe,
        MomentTimePipe,
        SafePipe,
        ChangeStyleDependingOnTimeElapsedPipe,
        HtmlPipe,
        MissionChipStatusPipe,
        MissionTypePipe,
        IdentificationMission,
        PainScalePipe
    ],
    imports: [],
    exports: [
        AssignationStatusPipe,
        AssignationDetailsStatusPipe,
        MomentTimePipe,
        SafePipe,
        ChangeStyleDependingOnTimeElapsedPipe,
        HtmlPipe,
        MissionChipStatusPipe,
        MissionTypePipe,
        IdentificationMission,
        PainScalePipe
    ]
})
export class PipesModule {}
