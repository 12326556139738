import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "painScale"
})
export class PainScalePipe implements PipeTransform {
    transform(painScale: number) {
        let painScaleText = "";
        switch (painScale) {
            case 0:
                painScaleText = `${painScale} : Pas de douleur`;
                break;
            case 1:
            case 2:
            case 3:
                painScaleText = `${painScale} : Douleur faible`;
                break;
            case 4:
            case 5:
            case 6:
                painScaleText = `${painScale} : Modérée`;
                break;
            case 7:
            case 8:
                painScaleText = `${painScale} : Forte`;
                break;
            case 9:
            case 10:
                painScaleText = `${painScale} : Insupportable`;
                break;
        }
        return painScaleText;
    }
}
