import {Component, ViewEncapsulation, NgZone} from '@angular/core';
import {environment} from '@env/environment';
import {Platform, AlertController, MenuController} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Market} from '@ionic-native/market/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import {Device} from '@ionic-native/device/ngx';
import {Router} from '@angular/router';
import {
    InAppBrowser,
    InAppBrowserOptions
} from '@ionic-native/in-app-browser/ngx';
import {HttpClient} from '@angular/common/http';

import {
    AuthService,
    NetworkService,
    AssignationsService,
    ConnectionStatus,
    SettingsService,
    UserService,
    AllEvent,
    ProductService
} from './services';
import {GoogleAnalytics} from '@ionic-native/google-analytics/ngx';
import {Platforms} from '@ionic/core';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [Market, GoogleAnalytics]
})
export class AppComponent {
    optionsIos: InAppBrowserOptions = {
        location: 'no',
        closebuttoncolor: '#f7f7f7',
        closebuttoncaption: 'X',
        toolbar: 'yes',
        toolbarposition: 'bottom',
        toolbarcolor: '#0BBBEF',
        presentationstyle: 'fullscreen'
    };
    optionsAndroid: InAppBrowserOptions = {
        location: 'yes',
        hidden: 'no',
        hideurlbar: 'yes',
        hardwareback: 'yes',
        toolbarcolor: '#0BBBEF',
        zoom: 'no'
    };

    isOnline = false;
    connexionOffText = 'Aucune connexion Internet';
    isOpenSubMenu = false;
    isOpen = -1;
    title = 'Libheros';
    user: any;
    version = environment.version;
    production = environment.production;
    data: any;
    productSheet: any;
    public appPages = [
        {
            title: "Accueil",
            url: "/tabs/assignations",
            icon: "calendar"
        },
        {
            title: "Facturation",
            url: "/",
            icon: "briefcase"
        },
        {
            title: "Télésoin",
            url: "/tabs/telecare",
            icon: "videocam"
        },
        {
            title: "Articles & Conseils",
            url: "/",
            icon: "paper"
        },
        {
            title: "Parcours de soins et traitements",
            icon: "document",
            url: "",
            subPages: [
                {
                    title: "Pathologies",
                    icon: "",
                    url: "",
                    img: "../../../../assets/imgs/pathologies.png",
                    subPages: [
                        {
                            title: "Sclérose en plaques",
                            url: "/tabs/parcours/sclerose-en-plaques",
                            src: "document"
                        },
                        {
                            title: "Asthme sévère",
                            url: "/tabs/parcours/asthme-severe",
                            icon: "document"
                        },
                        {
                            title: "Pathologie dermatologique",
                            url: "/tabs/sanofi",
                            icon: "hand"
                        }
                    ]
                },
                {
                    title: "Médicaments & Dispositifs médicaux",
                    img: "../../../../assets/imgs/medicaments.png",
                    url: "",
                    subPages: []
                }
            ]
        },
        {
            title: "Aide",
            url: "/tabs/contact",
            icon: "help-circle-outline"
        }
    ];
    constructor(
        public menuCtrl: MenuController,
        private theInAppBrowser: InAppBrowser,
        private http: HttpClient,
        private platform: Platform,
        private statusBar: StatusBar,
        public auth: AuthService,
        private zone: NgZone,
        private networkService: NetworkService,
        public events: AllEvent,
        private assignationsService: AssignationsService,
        private userService: UserService,
        // private oneSignal: OneSignal,
        private device: Device,
        private alertController: AlertController,
        public router: Router,
        private settingsService: SettingsService,
        private market: Market,
        private ga: GoogleAnalytics,
        private productService: ProductService
    ) {
        this.platform.ready().then(() => {
            this.statusBar.styleLightContent();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#0BBBEF');
            this.initOneSignalNotifications();
            this.checkAppVersion();
            this.networkService
                .onNetworkChange()
                .subscribe((status: ConnectionStatus) => {
                    this.isOnline = status === ConnectionStatus.Online;
                });

            /*  this.appRate.setPreferences({
                  promptAgainForEachNewVersion: false,
                  simpleMode: true,
                  customLocale: {
                      title: 'Vous appréciez l\'application libheros PRO ?',
                      message: 'Partagez votre avis en notant l\'application',
                      cancelButtonLabel: 'Non, Merci',
                      laterButtonLabel: 'Me le rappeler plus tard',
                      rateButtonLabel: 'Noter l\'application',
                      yesButtonLabel: 'Noter l\'application',
                      noButtonLabel: 'Me le rappeler plus tard',
                      appRatePromptTitle: 'Vous appréciez l\'application libheros PRO ?',
                      feedbackPromptTitle: 'Mind giving us some feedback?',
                  },
                  usesUntilPrompt: 5,
                  storeAppURL: {
                      ios: '1158705405',
                      android: 'market://details?id=com.libheros.pro>'
                  }
              });

              this.appRate.promptForRating(false);*/
        });
    }
    ngOnInit(): void {
        this.getDataPages();
        this.getProductSheet();
    }

    getProductSheet() {
        this.productService.getData().subscribe(data => {
            this.productSheet = data;
        });
    }

    getDataPages() {
        this.productService.getData().subscribe(
            data => {
                this.buildAppPages(data);
            },
            error => {
                console.error(
                    "Erreur lors de la récupération des données :",
                    error
                );
            }
        );
    }
    buildAppPages(sheetProduct) {
        const TreatmentSection = this.appPages.find(
            sec => sec.title === "Parcours de soins et traitements"
        );
        const sectioPages = TreatmentSection.subPages;
        if (sectioPages.length > 0) {
            sheetProduct.map(product => {
                let sectExist = sectioPages.find(
                    pg => pg.title === product.Section.section_name
                );
                if (sectExist) {
                    sectExist.subPages.push({
                        title: product.subsection_title,
                        url: `/tabs/product-sheet/${product.id}`,
                        icon: ""
                    });
                } else {
                    sectioPages.push({
                        title: product.Section.section_name,
                        url: "",
                        img:`${product.Section.img_link}`,
                        subPages: [
                            {
                                title: product.subsection_title,
                                url: `/tabs/product-sheet/${product.id}`,
                            }
                        ]
                    });
                }
            });
        }
    }


    private static isAuthenticated() {
        return AuthService.isAuthenticated();
    }

    startTracking() {
        this.ga.startTrackerWithId('UA-90640702-10').then(() => {
            this.ga.debugMode();
            const o = this.ga.trackEvent(
                'Evenement Pro',
                'Click Reblozyl',
                'Clique Boutton Reblozyl',
                1,
                true
            );
        });
    }

    initOneSignalNotifications() {
        const iosSettings = {};
        if (this.platform.is('cordova')) {
            OneSignal.setAppId('8c13341a-606d-4910-a8c6-fe9bc011a968');
            OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
                console.log('User accepted notifications: ' + accepted);
                if (accepted) {
                    OneSignal.getDeviceState((stateChanges) => {
                        const device: any = {
                            uuid: stateChanges['userId'],
                            token: stateChanges['pushToken'],
                            platform: this.device.platform,
                            version: this.device.version,
                            model: this.device.model,
                            manufacturer: this.device.manufacturer,
                            app_version: environment.version
                        };
                        return this.userService.storeToken(device).subscribe();
                    });
                }
            });
            OneSignal.setNotificationOpenedHandler((jsonData) => {
                if (AppComponent.isAuthenticated()) {
                    let assignationId;
                    const notification: any = jsonData['notification'];
                    if (this.platform.is('ios')) {
                        const type = notification.rawPayload['custom']['a']['type'];
                        const alertId = notification.rawPayload['custom']['a']['alertId'];
                        const missionId = notification.rawPayload['custom']['a']['missionId'];
                        if (type === 'alert') {
                            return this.router.navigateByUrl(
                                `/tabs/alerts/${alertId}`
                            );
                        }
                        if (type === 'mission') {
                            return this.router.navigateByUrl(
                                `/tabs/missions/${missionId}`
                            );
                        }
                        assignationId = notification.rawPayload['custom']['a']['assignationId']['id'];
                        return this.router.navigateByUrl(
                            `/tabs/assignations/${assignationId}`
                        );
                    }
                    if (this.platform.is('android')) {
                        const type = notification.additionalData.type;
                        const alertId = notification.additionalData.alertId;
                        const missionId = notification.additionalData.missionId;
                        if (type === 'alert') {
                            return this.router.navigateByUrl(
                                `/tabs/alerts/${alertId}`
                            );
                        }
                        if (type === 'mission') {
                            return this.router.navigateByUrl(
                                `/tabs/missions/${missionId}`
                            );
                        }
                        assignationId = notification.additionalData.assignationId.id;
                        return this.router.navigateByUrl(
                            `/tabs/assignations/${assignationId}`
                        );
                    }
                } else {
                    return this.router.navigate(['/login']);
                }
            });

        }
    }

    private checkAppVersion() {
        const currentAppVersion: any = environment.version;
        this.settingsService.getAppVersion().subscribe(
            appVersion => {
                const new_currentAppVersion = currentAppVersion.replaceAll('.', '');
                const new_appVersion = appVersion.replaceAll('.', '');
                // tslint:disable-next-line:radix
                const currentAppVersionInt = parseInt(new_currentAppVersion);
                // tslint:disable-next-line:radix
                const appVersionInt = parseInt(new_appVersion);

                if (currentAppVersionInt < appVersionInt) {
                    return this.doConfirm();
                }
            },
            error => {
                console.log('error', error);
            }
        );
    }

    async doConfirm() {
        const alert = await this.alertController.create({
            header: 'Mise à jour disponible',
            message:
                'Une nouvelle version de Libheros Pro est disponible, nous vous conseillons de la mettre à jour.',
            buttons: [
                {text: 'Non'},
                {
                    text: 'Oui',
                    handler: () => {
                        if (this.platform.is('android')) {
                            this.market.open('market://details?id=com.libheros.pro');
                        }
                        if (this.platform.is('ios')) {
                            this.market.open('1158705405');
                        }
                    }
                }
            ]
        });
        return await alert.present();
    }

    redirectToInAppBrowser(title) {
        const production = environment.production;
        const target = this.targetPlatForm('android', title);
        const options = this.targetPlatFormOption('android');
        if (title === 'Articles & Conseils') {
            this.theInAppBrowser.create(
                'https://blog.libheros.fr/professionnels-de-sante',
                target,
                options
            );
        } else if (title === 'Facturation') {
            options.toolbarposition = 'top';
            const token = localStorage.getItem('accessToken');
            const url = `${
                production
                    ? 'https://pro.libheros.fr'
                    : 'https://pro.lib-heros.com'
            }/auth/login/sso/${token}/facture`;
            this.theInAppBrowser.create(url, target, options);
        } else if (title === 'Parcours de soins et traitements') {
            this.isOpenSubMenu = !this.isOpenSubMenu;
            this.keepOpenMenu();
        }
    }

    redirectSubMenu(title: string) {
        const productExist = this.productSheet.find(product => {
           return product.subsection_title === title;
        });
        if (title === "Sclérose en plaques") {
            return this.router.navigateByUrl(
                `/tabs/parcours/sclerose-en-plaques`
            );
        } else if (title === 'Asthme sévère') {
            return this.router.navigateByUrl(`/tabs/parcours/asthme-severe`);
        } else if (title === 'Pathologie dermatologique') {
            return this.router.navigateByUrl(`/tabs/sanofi`);
        }
        else if (title === "REBLOZYL®") {
            const target = this.targetPlatForm("android", title);
            const options = this.targetPlatFormOption("android");
            this.startTracking();

            const production = environment.production;
            options.toolbarposition = "top";
            const token = localStorage.getItem("accessToken");
            const url = `${
                production
                    ? "https://pro.libheros.fr"
                    : "https://pro.lib-heros.com"
            }/auth/login/sso/${token}/reblozyl`;
            this.theInAppBrowser.create(url, target, options);
        } else if (productExist) {
            return this.router.navigateByUrl(
                `/tabs/product-sheet/${productExist.id}`
            );
        }
    }

    showOpenSubMenu(index) {
        if (this.isOpen === index) {
            this.isOpen = -1;
        } else {
            this.isOpen = index;
        }
        this.keepOpenMenu();
    }

    public keepOpenMenu(): void {
        this.menuCtrl.enable(false, 'main-content').then(() => {
            this.menuCtrl.enable(true, 'main-content');
        });
    }

    logOutClicked() {
        return this.presentAlertConfirm();
    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            message: 'Etes-vous sûr(e) de vouloir vous déconnecter ?',
            buttons: [
                {
                    text: 'Non',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                },
                {
                    text: 'Oui',
                    handler: () => {
                        this.menuCtrl.close();
                        return this.auth.logout().subscribe();
                    }
                }
            ]
        });

        await alert.present();
    }

    public targetPlatForm(device: string, title: string): string {
        const platform = this.platform.is(<Platforms>device);
        const titles = ['Facturation', 'REBLOZYL®'];
        return platform && titles.includes(title) ? '_system' : '_blank';
    }

    public targetPlatFormOption(device: string): InAppBrowserOptions {
        return this.platform.is(<Platforms>device)
            ? this.optionsAndroid
            : this.optionsIos;
    }
}
