import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class MissionsService {
    constructor(private api: ApiService) {}

    getMissionsAssignation(): Observable<any> {
        return this.api.get("/pros/account/missions/missions-assignations");
    }

    getAllMissions(): Observable<any> {
        return this.api.get("/admin/missions");
    }

    getMissionAssignation(id: string | number): Observable<any> {
        return this.api.get(
            "/pros/account/missions/missions-assignations/" + id
        );
    }

    getMissionDocuments(id: string | number): Observable<any> {
        return this.api.get(
            "/pros/account/missions/missions-assignations/mission-documents/" + id
        )
    }

    updateProtocolRead(id: string | number): Observable<any> {
        return this.api.put(
            "/pros/account/missions/missions-assignations/protocol",{
                id
            }
        );
    }

    getInfosMissions(missionId: number): Observable<any> {
        return this.api.get(
            "/pros/account/missions/" + missionId + "/infos-missions"
        );
    }

    updateSlot(slotsIds: string[]): Observable<any> {
        return this.api.post("/pros/account/missions/slot-assignations", {
            slotsIds
        });
    }

    getAllSubscribedPatients(
        missionId: number,
        slotId: number
    ): Observable<any> {
        return this.api.get(
            `/pros/account/missions/${missionId}/patients/slot/${slotId}`
        );
    }

    confirmPatientEligibleAndIdentification(
        patientId: number,
        eligible: boolean,
        coordinatorInfosId,
        id: number
    ): Observable<any> {
        return this.api.put(
            `/pros/account/patient/id/${patientId}/confirm-identification/mission/${id}`,
            {
                identified: true,
                eligible,
                coordinatorInfosId
            }
        );
    }

    updatePatientResult(patientId: number, patientInfos: any): Observable<any> {
        return this.api.put(`/pros/account/patient/id/${patientId}/result`, {
            patientInfos
        });
    }

    getPatient(
        patientId: number,
        slotId: number,
        isFromlab: boolean
    ): Observable<any> {
        return this.api.get(
            `/pros/account/patient/id/${patientId}/slot/${slotId}?isFromLab=${isFromlab}`
        );
    }

    getSlots(
        missionAssignationId: number,
        currentSlots: boolean
    ): Observable<any> {
        return this.api.get(
            `/pros/account/missions/missions-assignations/${missionAssignationId}/slots/${currentSlots}`
        );
    }

    generateAndSendCertificateForFluVaccination(
        missionId: number,
        patient: any
    ): Observable<any> {
        return this.api.post(
            `/pros/account/missions/${missionId}/flu-certificate`,
            {patient}
        );
    }
    updateBreastCancer(id: string, medicalInfos: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-breast-cancer-infos/${id}`,
            medicalInfos
        );
    }
    updateWomensHealth(id: string, medicalInfos: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-health-women-infos/${id}`,
            medicalInfos
        );
    }
    updateCardioVascularDisease(
        id: string,
        medicalInfos: object
    ): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-mcv-infos/${id}`,
            medicalInfos
        );
    }
    updateEndometriosis(id: string, medicalInfos: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-endometriosis-infos/${id}`,
            medicalInfos
        );
    }
    updateStress(id: string, medicalInfos: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-stress-infos/${id}`,
            medicalInfos
        );
    }

    updateVisit(id: string, patientId: string, visit: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/${patientId}/visit/${id}`,
            visit
        );
    }

    updateMelanomaHealth(id: string, medicalInfos: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-melanoma-infos/${id}`,
            medicalInfos
        );
    }
    updateMenHealth(id: string, medicalInfos: object): Observable<any> {
        return this.api.put(
            `/pros/account/patient/coordinator-patient-health-men-infos/${id}`,
            medicalInfos
        );
    }

    getCoordinatorPatientEndometriosisInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
                patientId +
                "/slot/" +
                slotId +
                "/coordinator-patient-endometriosis-infos"
        );
    }

    getCoordinatorPatientStressInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
                patientId +
                "/slot/" +
                slotId +
                "/coordinator-patient-stress-infos"
        );
    }

    getCoordinatorPatientNovartisInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
                patientId +
                "/slot/" +
                slotId +
                "/coordinator-patient-novartis-infos"
        );
    }

    getCoordinatorPatientBreastCancerInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
            patientId +
            "/slot/" +
            slotId +
            "/coordinator-patient-breast-cancer-infos"
        );
    }

    getCoordinatorPatientHealthWomenInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
                patientId +
                "/slot/" +
                slotId +
                "/coordinator-patient-health-women-infos"
        );
    }

    getCoordinatorPatientMelanomaInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
                patientId +
                "/slot/" +
                slotId +
                "/coordinator-patient-melanoma-infos"
        );
    }

    getCoordinatorPatientHealthMenInfos(
        patientId: string,
        slotId: string
    ): Observable<any> {
        return this.api.get(
            "/pros/account/patient/" +
            patientId +
            "/slot/" +
            slotId +
            "/coordinator-patient-health-men-infos"
        );
    }
    patchPerimeter(
        form: FormGroup,
        perimeter: string,
        value: number,
        max: any
    ) {
        if (value) {
            const intToStringValue = value.toString();
            let parsedValue;
            if (intToStringValue.includes(".")) {
                parsedValue = Number(intToStringValue.substr(0, max.float));
            } else {
                parsedValue = Number(intToStringValue.substr(0, max.integer));
            }
            form.get(perimeter).patchValue(parsedValue, {
                emitEvent: false,
                onlySelf: true
            });
        }
    }

    updateRouteForMissionPathway(
        missionAssignationId: number,
        slotId: number,
        patientId: number,
        missionType: string
    ): string {
        let url = `/tabs/identification-mission/${missionAssignationId}/slot/${slotId}/patient/${patientId}`;
        switch (missionType) {
            case "prevention maladie cardio-vasculaire":
                url += "/mcv";
                break;
            case "prevention endometriose":
                url += "/endometriosis";
                break;
            case "prevention stress":
                url += "/stress";
                break;
            case "cancer du sein":
                url += "/breast_cancer";
                break;
            case "prevention sante de la femme":
                url += "/health-women";
                break;
            case "prevention cancer de la peau":
                url += "/melanoma";
                break;
            case "prevention sante de l homme":
                url += "/health-men";
                break;
        }
        return url;
    }

    createAndSendPdf(medicalInfoId: string, type: string): Observable<any> {
        return this.api.post(
            `/pros/account/missions/${type}/${medicalInfoId}`,
            {}
        );
    }
}
