import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({name: "assignationDetailsStatus"})
export class AssignationDetailsStatusPipe implements PipeTransform {
    constructor() {}

    transform(assignation, outcome) {
        if (!assignation) {
            return false;
        }

        switch (assignation.status) {
            case "ADMIN_TO_ORIGINAL_PRO":
            case "ACCEPTED":
                assignation.text =
                    "Accepté le " +
                    moment(assignation.closed_at).format("DD/MM/YYYY à HH:mm");
                assignation.color = "success";
                break;
            case "CANCELLED_BY_PATIENT":
                assignation.text =
                    "Le patient a finalement annulé sa demande de soin. Vous ne pouvez plus répondre à cette demande de soin";
                assignation.color = "light";
                break;
            case "CANCELLED_BY_PRO_WITH_RECO":
                assignation.text =
                    "Vous avez recommandé un collègue à ce patient le " +
                    moment(assignation.answered_at).format(
                        "DD/MM/YYYY à HH:mm"
                    );
                assignation.color = "light";
                break;
            case "CANCELLED_BY_PRO":
                assignation.text = "Vous avez annulé ce rendez-vous";
                assignation.color = "light";
                break;
            case "ADMIN_PATIENT_ALONE":
                assignation.text =
                    "Le patient a finalement trouvé un professionnel de santé par ses propres moyens. Vous ne pouvez plus répondre à cette demande de soin";
                assignation.color = "light";
                break;
            case "ADMIN_PATIENT_NO_ANSWER":
                assignation.text =
                    "Depuis sa demande de soin, le patient est injoignable, vous ne pouvez pas répondre à cette demande de soin";
                assignation.color = "light";
                break;
            case "ADMIN_TO_STRUCTURE":
                assignation.text =
                    "Le patient a finalement été orienté vers une structure de soins qui va le prendre en charge. Vous ne pouvez plus répondre à cette demande de soin";
                assignation.color = "light";
                break;
            case "PATIENT_TO_OTHER_PRO_LIBHEROS":
            case "ADMIN_TO_EXTERNAL_PRO":
                assignation.text =
                    " Le patient a été orienté vers un autre professionnel de santé, vous ne pouvez plus répondre à la demande de soin";
                assignation.color = "light";
                break;
            case "AUTO_TO_OTHER_PRO_LIBHEROS":
            case "ADMIN_TO_OTHER_PRO_LIBHEROS":
            case "ADMIN_PROS_LIST":
                const date: string = moment(
                    assignation.answered_at || assignation.closed_at
                ).format("DD/MM/YYYY à HH:mm");
                assignation.text = assignation.answered_at
                    ? `Annulé par votre cabinet le ${date}`
                    : `Le support a transféré ce rendez-vous le ${date}`;
                assignation.color = "danger";
                break;
            case "AUTO_PRO_LIST":
            case "TIME_OUT_TO_NEXT_PRO":
                assignation.text =
                    assignation.status === "TIME_OUT_TO_NEXT_PRO"
                        ? "Le délai de réponse est dépassé, le patient a été redirigé vers un autre cabinet"
                        : "Le délai de réponse est dépassé, une liste de cabinets a été envoyée au patient";
                assignation.color = "light";
            case "CLOSED":
                assignation.text = "Le délai de réponse est dépassé, le patient a été redirigé vers un autre cabinet"
                assignation.color = "light";
                break;
        }
        return assignation;
    }
}
