import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "momentTime"
})
export class MomentTimePipe implements PipeTransform {
    transform(assignationDate: any): any {
        moment.locale("FR");

        if (!assignationDate) {
            return "-";
        }

        const duration = moment
            .duration(moment().diff(assignationDate))
            .as("days");

        if (duration < 7) {
            const time = moment(assignationDate).fromNow();
            return time;
        }

        if (duration >= 7) {
            const time =
                "le " + moment(assignationDate).format("DD/MM/YY à H:mm");
            return time;
        }

        return "NotFormat";
    }
}
