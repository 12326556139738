import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: "root"
})
export class UserService {
    constructor(private api: ApiService) {}

    storeToken(device) {
        return this.api.put("/devices", device);
    }
}
