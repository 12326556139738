import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {NetworkService, ConnectionStatus} from "./network.service";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    constructor(
        private http: HttpClient,
        private networkService: NetworkService
    ) {}

    private static updateUrl(req: string) {
        return environment.origin + req;
    }

    get(url: string) {
        url = ApiService.updateUrl(url);
        return this.http.get(url);
    }

    /* get(url: string) {
         url = ApiService.updateUrl(url);
         if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
             return;
         } else {
             return this.http.get(url);
         }
     }
 */
    // post(url: string, body: any) {
    //     url = ApiService.updateUrl(url);
    //     if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //         return;
    //     } else {
    //         return this.http.post(url, body);
    //     }
    // }

    post(url: string, body: any) {
        url = ApiService.updateUrl(url);
        return this.http.post(url, body);
    }

    /* put(url: string, body: any) {
        url = ApiService.updateUrl(url);
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            return;
        } else {
            return this.http.put(url, body);
        }
    }*/

    put(url: string, body: any) {
        url = ApiService.updateUrl(url);
        return this.http.put(url, body);
    }

    delete(url: string) {
        url = ApiService.updateUrl(url);
        return this.http.delete(url);
    }
}
