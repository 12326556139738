import "rxjs/add/operator/map";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "./api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {tap, catchError} from "rxjs/operators";
import {Toast} from "./toast";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private authenticationState = new BehaviorSubject(null);
    public userState = new BehaviorSubject(null);
    user = this.userState.asObservable();

    constructor(
        private api: ApiService,
        private router: Router,
        public toastCtrl: Toast
    ) {}

    static getAccessToken() {
        return localStorage.getItem("accessToken");
    }

    static isAuthenticated(): boolean {
        return !!AuthService.getAccessToken();
    }

    login(credentials: any) {
        return this.api.post("/pros/auth/login", credentials).pipe(
            tap(async res => {
                localStorage.setItem("email_user", credentials.email);
                localStorage.setItem("accessToken", res["token"]);
                this.authenticationState.next({id: res["id"]});
                return res;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    register(userData: any) {
        return this.api.post("/pros/mobile/auth/register", userData).pipe(
            tap(async res => {
                localStorage.setItem("email_user", userData.email);
                localStorage.setItem("accessToken", res["token"]);
                this.authenticationState.next({
                    id: res["id"],
                    team_id: res["team_id"]
                });
                return res;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    endConfig(): Observable<any> {
        return this.api.put("/pros/account/end-config", {});
    }

    logout() {
        return this.api.delete("/pros/auth/logout").pipe(
            tap(async () => {
                this.router.navigate(["/login"]);
                localStorage.removeItem("accessToken");
                this.authenticationState.next(false);
            }),
            catchError(error => {
                const msg = error.error.message;
                throw new Error(msg);
            })
        );
    }

    sendRestMailPassword(credentials) {
        return this.api.put("/pros/auth/reset", credentials);
    }

    updatePassword({new_password, password}) {
        return this.api.put("/pros/account/password", {new_password, password});
    }
}
