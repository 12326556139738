import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Rx";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: "root"
})
export class AlertsService {
    constructor(private api: ApiService) {}

    fetchAll(): Observable<any> {
        return this.api.get("/alerts/pros");
    }

    findOne(id): Observable<any> {
        return this.api.get("/alerts/" + id);
    }

    update(id): Observable<any> {
        return this.api.put("/alerts/" + id, null);
    }

    delete(id): Observable<any> {
        return this.api.delete("/alerts/" + id + "/");
    }

    listUnreadAlerts(): Observable<any> {
        return this.api.get("/alerts/unread/badge");
    }

    replyStatus(status, alert): Observable<any> {
        alert.status = status;
        return this.api.put("/alerts/" + alert._id + "/hooks/", {alert: alert});
    }
}
