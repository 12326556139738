import {Component, OnInit, Input} from "@angular/core";
import {ModalController} from "@ionic/angular";

@Component({
    selector: "app-forbidden-team-names",
    templateUrl: "./forbidden-team-names-modal.html",
    styleUrls: ["./forbidden-team-names-modal.scss"]
})
export class ForbiddenTeamNamesModal implements OnInit {
    @Input() baseTeamName: string;
    @Input() textList: string[];
    @Input() shownAsError: boolean;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    public async closeModal() {
        const modal = await this.modalCtrl.getTop();
        return modal.dismiss();
    }
}
