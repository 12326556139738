import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class Toast {
    constructor(public toastCtrl: ToastController) {}

    async create(msg, status, position) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 2000,
            position: position,
            color: status,
            showCloseButton: true,
            closeButtonText: "x"
        });
        return toast.present();
    }
}
