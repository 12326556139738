import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Rx";

@Injectable({
    providedIn: "root"
})
export class SettingsService {
    constructor(private api: ApiService) {}

    getAppVersion(): Observable<any> {
        return this.api.get("/settings/app-version");
    }
}
