import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AllEvent {
    public alertEvent = new BehaviorSubject(null);
    alert = this.alertEvent.asObservable();

    constructor(public toastCtrl: ToastController) {}
}
