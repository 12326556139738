import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "missionType"})
export class MissionTypePipe implements PipeTransform {
    constructor() {}
    transform(missionType: string) {
        let missionTypeText: string;
        switch (missionType) {
            case "prevention maladie cardio-vasculaire":
                missionTypeText = "Maladies Cardiovasculaires";
                break;
            case "prevention endometriose":
                missionTypeText = "Endométriose";
                break;
            case "prevention stress":
                missionTypeText = "Prévention contre le stress";
                break;
            case "prevention cancer de la peau":
                missionTypeText = "Mélanome";
                break;
            default:
                missionTypeText = missionType;
                break;
        }
        return missionTypeText;
    }
}
