import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "assignationStatus"})
export class AssignationStatusPipe implements PipeTransform {
    constructor() {}

    transform(assignation, outcome) {
        if (!assignation) {
            return false;
        }
        const acceptedStatuses = ["ACCEPTED", "ADMIN_TO_ORIGINAL_PRO"];
        const openStatuses = ["OPEN", "ADMIN_WAITING"];
        const cancelledStatuses = [
            "CANCELLED_BY_PRO",
            "AUTO_TO_OTHER_PRO_LIBHEROS",
            "ADMIN_TO_EXTERNAL_PRO",
            "ADMIN_TO_OTHER_PRO_LIBHEROS",
            "ADMIN_PATIENT_ALONE",
            "ADMIN_TO_STRUCTURE",
            "CANCELLED_BY_PRO_WITH_RECO",
            "PATIENT_TO_OTHER_PRO_LIBHEROS",
            "ADMIN_PATIENT_NO_ANSWER",
            "ADMIN_PROS_LIST"
        ];
        const allowedStatuses = acceptedStatuses.concat(openStatuses);

        switch (outcome) {
            case "accepted":
                return acceptedStatuses.includes(assignation.status);
            case "open":
                return openStatuses.includes(assignation.status);
            case "cancelled":
                return (
                    cancelledStatuses.includes(assignation.status) &&
                    assignation.answered_at
                );
            case "transfered":
                return (
                    cancelledStatuses.includes(assignation.status) &&
                    !assignation.answered_at
                );
            case "allowed":
                return allowedStatuses.includes(assignation.status);
            case "cancelledByPatient":
                return assignation.status === "CANCELLED_BY_PATIENT";
            default:
                return false;
        }
    }
}
