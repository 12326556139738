import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "missionChipStatus"
})
export class MissionChipStatusPipe implements PipeTransform {
    transform(patient: any, missionType: string, outputType: string): string {
        const missionTypesWithValidatedIndicator = [
            "prevention maladie cardio-vasculaire",
            "prevention endometriose",
            "prevention stress",
            "cancer du sein",
            "prevention sante de la femme",
            "prevention cancer de la peau",
            "prevention sante de l homme"
        ];
        if (outputType === "text") {
            if (missionType === "depistage") {
                if (
                    patient.identified &&
                    !patient.test_result &&
                    !patient.test_performed
                ) {
                    return patient.civility === "F"
                        ? "Identifié non testée"
                        : "Identifié non testé";
                } else if (patient.identified && patient.test_result) {
                    return patient.civility === "F"
                        ? "Identifiée et testée"
                        : "Identifié et testé";
                } else if (patient.identified && patient.test_performed) {
                    return "En attente du résultat";
                } else {
                    return patient.civility === "F"
                        ? "Non identifiée"
                        : "Non identifié";
                }
            } else if (missionType === "prevention") {
                if (patient.eligible) {
                    return patient.civility === "F" ? "Présente" : "Présent";
                } else {
                    return patient.civility === "F"
                        ? "Non Présente"
                        : " Non Présent";
                }
            } else if (
                missionTypesWithValidatedIndicator.includes(missionType)
            ) {
                const medicalInfos = MissionChipStatusPipe.defineMedicalInfos(
                    missionType,
                    patient
                );

                if (medicalInfos && !medicalInfos.is_interviewed) {
                    return patient.civility === "F"
                        ? "Non validée"
                        : "Non validé";
                } else if (medicalInfos && medicalInfos.is_interviewed) {
                    return patient.civility === "F" ? "Validée" : "Validé";
                }
            } else {
                const medicalInfos =
                    missionType === "vaccination anti-covid19"
                        ? patient.CoordinatorPatientVaccinationInfo
                        : patient.CoordinatorPatientFluInfo;
                if (
                    medicalInfos &&
                    medicalInfos.vaccination_date &&
                    missionType === "vaccination anti-covid19"
                ) {
                    return patient.civility === "F" ? "Vaccinée" : "Vacciné";
                } else if (
                    medicalInfos && (medicalInfos.vaccination_date && missionType === "vaccination antigrippale")
                ) {
                    let text =
                        patient.civility === "F" ? "Vaccinée" : "Vacciné";
                    const patientHasCertificate: boolean =
                        patient.Files && patient.Files.length > 0;
                    text += patientHasCertificate
                        ? ", attestation envoyée"
                        : ", attestation non envoyée";
                    return text;
                }  else if (
                    missionType ===
                        "vaccination antigrippale et anti-covid19" &&
                    ((patient.CoordinatorPatientFluAndVaccinationInfo &&
                        patient.CoordinatorPatientFluAndVaccinationInfo
                            .covid19_vaccination) ||
                        (medicalInfos && medicalInfos.vaccination_date))
                ) {
                    let text =
                        patient.civility === "F" ? "Vaccinée" : "Vacciné";
                    if (
                        patient.CoordinatorPatientFluAndVaccinationInfo &&
                        patient.CoordinatorPatientFluAndVaccinationInfo.type.includes(
                            "FLU"
                        )
                    ) {
                        const patientHasCertificate: boolean =
                            patient.Files && patient.Files.length > 0;
                        text += patientHasCertificate
                            ? ", attestation envoyée"
                            : ", attestation non envoyée";
                    }
                    return text;
                } else if (patient.eligible) {
                    return patient.civility === "F"
                        ? "Eligible, non vaccinée"
                        : "Eligible, non vacciné";
                } else if (patient.identified && !patient.eligible) {
                    return "Non éligible";
                } else {
                    return patient.civility === "F"
                        ? "Non identifiée"
                        : "Non identifié";
                }
            }
        } else {
            if (missionType === "depistage") {
                if (
                    patient.identified &&
                    !patient.test_result &&
                    !patient.test_performed
                ) {
                    return "warning";
                } else if (patient.identified && patient.test_result) {
                    return "success";
                } else if (patient.identified && patient.test_performed) {
                    return "primary";
                } else {
                    return "";
                }
            } else if (missionType === "prevention") {
                if (patient.eligible) {
                    return "success";
                } else {
                    return "warning";
                }
            } else if (
                missionTypesWithValidatedIndicator.includes(missionType)
            ) {
                const medicalInfos = MissionChipStatusPipe.defineMedicalInfos(
                    missionType,
                    patient
                );
                if (medicalInfos && !medicalInfos.is_interviewed) {
                    return "danger";
                } else if (medicalInfos && medicalInfos.is_interviewed) {
                    return "success";
                }
            } else {
                const medicalInfos =
                    missionType === "vaccination anti-covid19"
                        ? patient.CoordinatorPatientVaccinationInfo
                        : patient.CoordinatorPatientFluInfo;
                if (
                    medicalInfos &&
                    medicalInfos.vaccination_date &&
                    missionType === "vaccination anti-covid19"
                ) {
                    return "success";
                } else if (
                    medicalInfos &&
                    medicalInfos.vaccination_date &&
                    missionType === "vaccination antigrippale"
                ) {
                    const patientHasCertificate: boolean =
                        patient.Files && patient.Files.length > 0;
                    return patientHasCertificate ? "success" : "danger";
                } else if (
                    (medicalInfos && medicalInfos.vaccination_date ||
                    patient.CoordinatorPatientFluAndVaccinationInfo && patient.CoordinatorPatientFluAndVaccinationInfo
                        .covid19_vaccination) &&
                    missionType === "vaccination antigrippale et anti-covid19"
                ) {
                    return (medicalInfos && medicalInfos.vaccination_date && patient.Files && patient.Files.length > 0  ||
                        patient.CoordinatorPatientFluAndVaccinationInfo && patient.CoordinatorPatientFluAndVaccinationInfo.covid19_vaccination &&
                        patient.CoordinatorPatientFluAndVaccinationInfo && !patient.CoordinatorPatientFluAndVaccinationInfo.type.includes("FLU"))
                        ? "success"
                        : "danger";
                } else if (patient.eligible) {
                    return "warning";
                } else if (patient.identified && !patient.eligible) {
                    return "danger";
                } else {
                    return "";
                }
            }
        }
    }

    private static defineMedicalInfos(missionType: string, patient: any): any {
        let medicalInfos: any;
        switch (missionType) {
            case "prevention maladie cardio-vasculaire":
                medicalInfos = patient.CoordinatorPatientMcvInfo;
                break;
            case "prevention endometriose":
                medicalInfos = patient.CoordinatorPatientEndometriosisInfo;
                break;
            case "prevention stress":
                medicalInfos = patient.CoordinatorPatientStressInfo;
                break;
            case "cancer du sein":
                medicalInfos = patient.CoordinatorPatientBreastCancerInfo;
                break;
            case "prevention sante de la femme":
                medicalInfos = patient.CoordinatorPatientHealthWomenInfo;
                break;
            case "prevention cancer de la peau":
                medicalInfos = patient.CoordinatorPatientMelanomaInfo;
                break;
            case "prevention sante de l homme":
                medicalInfos = patient.CoordinatorPatientHealthMenInfo;
                break;

        }
        return medicalInfos;
    }
}
