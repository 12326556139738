import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "changeStyleDependingOnTimeElapsed"
})
export class ChangeStyleDependingOnTimeElapsedPipe implements PipeTransform {
    transform(assignationDate: any): any {
        const timeAgo = moment().diff(moment(assignationDate), "hours");
        let color;

        if (timeAgo < 2) {
            color = "shortNotice";
        } else {
            color = "longNotice";
        }
        return color;
    }
}
