export const environment = {
    production: true,
    beta: false,
    // site: 'http://localhost:3000/',
    //   origin: 'http://localhost:1234',
    // site: 'https://libheros.fr/',
    //origin: 'https://api.libheros.fr',
    site: "https://site.lib-heros.com/",
    origin: "https://api.lib-heros.com",
    // flowUrl: 'https://flow.libheros.fr',
    flowUrl: "https://flow.lib-heros.com",
    version: "4.5.18"
};
