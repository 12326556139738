import {Injectable} from "@angular/core";
import {ForbiddenTeamNamesModal} from "../modal/forbidden-team-names/forbidden-team-names-modal";

@Injectable({
    providedIn: "root"
})
export class TeamNameService {
    constructor() {}

    static openTeamNamesModal(jobId: number, shownAsError: boolean) {
        let forbiddenName: string;
        const baseTeamName = jobId === 4 ? "Laboratoire" : "Cabinet";
        switch (jobId) {
            case 1:
                forbiddenName = `"Cabinet infirmier"`;
                break;

            case 2:
                forbiddenName = `"Cabinet de kinésithérapie"`;
                break;

            case 3:
                forbiddenName = `"Cabinet de sage-femme"`;
                break;

            default:
                forbiddenName = `"Laboratoire d'analyses" ou "Laboratoire de biologie médicale"`;
        }

        const textList = [
            `Commence forcément par "${baseTeamName}"`,
            `Ne doit pas être un nom fantaisiste ou sonnant comme de la publicité`,
            `Ne doit pas seulement être "${baseTeamName}" ou ${forbiddenName}`,
            `De plus, sachez que les ${baseTeamName.toLocaleLowerCase()}s ne sont jamais affichés par ordre alphabétique mais toujours aléatoirement`
        ];
        return {
            component: ForbiddenTeamNamesModal,
            componentProps: {
                baseTeamName: baseTeamName,
                textList: textList,
                shownAsError: shownAsError
            }
        };
    }

    checkTeamName(jobId: number, teamName: string): string | null {
        teamName = this.updateNameWithUpperCase(teamName);
        return this.checkTeamNameValidation(jobId, teamName);
    }

    updateNameWithUpperCase(teamName: string): string {
        teamName = teamName.toLowerCase();
        let teamNameArray: Array<string> = teamName.split(" ");
        teamNameArray = teamNameArray.map((word: string, index: number) => {
            if (index === 0 && word.startsWith("de")) {
                return word;
            }
            if (word.includes("-")) {
                const newWordArray = word.split("-");
                return newWordArray
                    .map(
                        (word: string) => word[0].toUpperCase() + word.slice(1)
                    )
                    .join("-");
            } else if (word.includes("'")) {
                const newWordArray = word.split("'");
                return newWordArray
                    .map(
                        (word: string) => word[0].toUpperCase() + word.slice(1)
                    )
                    .join("'");
            } else {
                return word[0].toUpperCase() + word.slice(1);
            }
        });
        return teamNameArray.join(" ");
    }

    checkTeamNameValidation(jobId: number, teamName: string): string | null {
        const officeName =
            jobId !== 4 ? `Cabinet ${teamName}` : `Laboratoire ${teamName}`;
        let testOfficeName = officeName;
        const regex = /é|de|-|d'|w*s\b/gim;
        testOfficeName = testOfficeName.replace(regex, (p1: string) => {
            let result: string;
            switch (p1) {
                case "é":
                    result = "e";
                    break;

                case "de" || "d'" || "s":
                    result = "";
                    break;

                case "-":
                    result = " ";
                    break;

                default:
                    result = "";
            }

            return result;
        });
        testOfficeName = testOfficeName.replace(/  /, " ");
        const forbiddenName = [
            "cabinet infirmier",
            "cabinet kinesitherapie",
            "cabinet sage femme",
            "laboratoire",
            "laboratoire analyse",
            "laboratoire biologie medicale"
        ];
        if (forbiddenName.includes(testOfficeName.toLowerCase())) {
            return null;
        } else {
            return officeName.split(" ").slice(1).join(" ");
        }
    }
}
