import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "identificationMission"})
export class IdentificationMission implements PipeTransform {
    constructor() {}
    transform(missionType: string) {
        let missionTypeText = "";
        switch (missionType) {
            case "prevention maladie cardio-vasculaire":
            case "prevention endometriose":
                missionTypeText = "entretiens validés";
                break;
            case "vaccination anti-covid19":
            case "vaccination antigrippale":
            case "vaccination":
                missionTypeText = "participants vaccinés";
                break;
            case "prevention":
                missionTypeText = "patients";
                break;
            case "depistage":
                missionTypeText = "participants testés";
                break;
        }
        return missionTypeText;
    }
}
